@import '_colours.scss';
@import '_fonts.scss';

body {
    font-family:$font;
    font-size: 16px;
    color: $grey;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:$green;
    font-size:35px;
}
.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-subtitle {
    color: $green;
}
.page-content.two-col .highlight {
    font-size: 25px;
}
.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: $white;
    background-color: $yellow;
    border: 1px solid $yellow;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover,
.button.read-more:hover {
    color: $white;
    background-color: $green;
    border: 1px solid $green;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}